import { Box, Grid, Stack, Typography } from "@mui/material";

import { bulletPointData } from "../constants/bullet-point-data";

export function HomeBulletPoints() {
  return (
    <Stack
      sx={{
        flexDirection: {
          xs: "column-reverse",
          md: "row",
        },
        background:
          "linear-gradient(141.8deg,rgb(235, 247, 241) 15.7%,rgb(226, 242, 253) 55.9%,rgb(246, 251, 198) 109.22%)",
        borderRadius: 4,
        p: {
          md: 4,
          sm: 2,
          xs: 0,
        },
      }}
    >
      <Grid
        container
        component="ul"
        sx={{
          flex: 1,
          color: "#444444",
          listStyleType: "none",
          p: {
            xs: 2,
          },
        }}
      >
        {bulletPointData.map((data, index) => (
          <Grid key={`${index}_${data}`} item sm={6} md={12}>
            <Typography
              component="li"
              sx={{
                fontSize: {
                  xs: "5vw",
                  sm: "2.4vw",
                },
              }}
            >
              ✅ {data}
            </Typography>
          </Grid>
        ))}
        <Grid item md={6} lg={12}>
          <Typography
            component="span"
            variant="subtitle2"
            sx={{
              fontSize: {
                xs: "3vw",
                sm: "1.8vw",
              },
            }}
          >
            *currency conversions may reduce payout
          </Typography>
        </Grid>
      </Grid>
      <Stack
        sx={{
          flexBasis: "40%",
          justifyContent: "center",
        }}
      >
        <Stack>
          <Box
            component="img"
            alt="wishtender cash graphic"
            src="images/to_you_optimized.png"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
