import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";
import { init } from "ityped";
import { useEffect } from "react";

import { Routes } from "~/constants/routes";

import { typeChangeData } from "../constants/type-change-data";

export function HomeHeroSection() {
  useEffect(() => {
    const typeChangeElement = document.querySelector("#type-change");
    if (typeChangeElement)
      init(typeChangeElement, {
        strings: typeChangeData,
        showCursor: false,
      });
  }, []);

  return (
    <Stack
      sx={{
        height: {
          xs: "140vw",
          md: "55vw",
        },
        background: 'url("./images/hero_0522_blue_optimized.png") bottom center no-repeat',
        backgroundSize: {
          xs: "100%",
          md: "70%",
        },
        backgroundPosition: {
          xs: "center 20vw",
          md: "40vw center",
        },
      }}
    >
      <Stack
        spacing={4}
        sx={{
          justifyContent: {
            xs: "flex-end",
            md: "center",
          },
          alignItems: {
            xs: "center",
            md: "start",
          },
          flex: 1,
          pl: {
            xs: 0,
            md: 10,
          },
        }}
      >
        <Typography
          component="span"
          sx={{
            fontFamily: "Nunito,Roboto,Helvetica Neue,Arial,sans-serif",
            fontWeight: "bold",
            fontSize: {
              xs: "6vw",
              md: "4vw",
            },
            textAlign: {
              xs: "center",
              md: "left",
            },
            color: "#444444",
            mt: {
              xs: 2,
              md: 0,
            },
          }}
        >
          Get your{" "}
          <Typography
            component="span"
            id="type-change"
            sx={{
              fontFamily: "Nunito,Roboto,Helvetica Neue,Arial,sans-serif",
              fontWeight: "bold",
              fontSize: {
                xs: "6vw",
                md: "4vw",
              },
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          ></Typography>
          <Box component="br" />
          funded by your fans
        </Typography>
        <Stack>
          <Typography
            component="span"
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "3.6vw",
                md: "1.8vw",
              },
              color: "#444444",
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            The international wishlist that protects you.
          </Typography>
          <Typography
            component="span"
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "3.2vw",
                md: "1.4vw",
              },
              color: "#444444",
              textAlign: {
                xs: "center",
                md: "left",
              },
            }}
          >
            Receive gift funds fast and worry free.
          </Typography>
        </Stack>

        <Link component={RLink} to={Routes.auth.signUp()}>
          <Button
            disableElevation
            variant="contained"
            sx={{
              fontWeight: 600,
              fontSize: {
                sm: "1.3rem",
              },
              background: (theme) => theme.gradient.main,
              ":hover": {
                background: (theme) => theme.gradient.mainReverse,
              },
            }}
          >
            Create Your Free Wishlist
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
}
