import { Box, Grid, Paper, Stack, Typography } from "@mui/material";

import { fourPointData } from "../constants/four-point-data";

export function HomeFourPointExplanation() {
  return (
    <Stack
      sx={{
        alignItems: "center",
        borderRadius: 4,
        background: "linear-gradient(141.8deg,#faf3cf 15.7%,#cdf2fd 55.9%,#e0dcfb 109.22%)",
        pt: 10,
      }}
    >
      <Typography
        component="span"
        sx={{
          fontSize: "2rem",
          fontWeight: "bold",
          textAlign: "center",
          px: 2,
        }}
      >
        A free and safe way to share wishlists.
      </Typography>
      <Stack
        sx={{
          p: 6,
        }}
      >
        <Grid container spacing={6}>
          {fourPointData.map((data) => (
            <Grid key={data.iconSrc} item xs={12} sm={6} lg={3}>
              <Paper
                sx={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                  flexDirection: "column",
                  borderBottom: "30px solid #ffe366",
                  p: 2,
                  gap: 4,
                }}
              >
                <Stack
                  sx={{
                    width: "50%",
                  }}
                >
                  <Box component="img" src={data.iconSrc} alt={data.iconAlt} />
                </Stack>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {data.title}
                </Typography>
                <Typography
                  component="p"
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {data.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
