export const fourPointData = [
  {
    title: "One Stop Wishlist",
    description:
      "Pick from any online store or create custom cash funds for college, travel, and more.",
    iconSrc: "images/icon_wishlist.png",
    iconAlt: "wishlist icon",
  },
  {
    title: "Hide Shipping Address",
    description:
      "Keep your mailing address and legal name private so you can receive gifts safely.",
    iconSrc: "images/icon_secure_address.png",
    iconAlt: "security icon",
  },
  {
    title: "Control Orders",
    description: "Decide when your orders get placed, add to orders, or keep the cash.",
    iconSrc: "images/icon_control_orders.png",
    iconAlt: "control orders icon",
  },
  {
    title: "Non-Judgmental",
    description: "WishTender is built to help you no matter what content you create.",
    iconSrc: "images/icon_no_judgement_web_optimized.png",
    iconAlt: "no judgement icon",
  },
];
