import { Box, Stack } from "@mui/material";

import { GeneralLayout } from "~/components/general-layout";
import { HomeBulletPoints } from "~/modules/landing/home/components/home-bullet-points";
import { HomeExplanation } from "~/modules/landing/home/components/home-explanation";
import { HomeFourPointExplanation } from "~/modules/landing/home/components/home-four-point-explanation";
import { HomeHeroSection } from "~/modules/landing/home/components/home-hero-section";

export function HomePage() {
  return (
    <GeneralLayout>
      <Box
        sx={{
          background: "linear-gradient(0deg, #f2f3f5, #ffffff)",
        }}
      >
        <HomeHeroSection />
        <Stack
          spacing={{
            xs: 4,
            sm: 10,
          }}
          sx={{
            px: {
              xs: 2,
              sm: 8,
            },
            pt: 8,
          }}
        >
          <HomeBulletPoints />
          <HomeFourPointExplanation />
        </Stack>
        <HomeExplanation />
      </Box>
    </GeneralLayout>
  );
}
