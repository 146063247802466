import { Button, Link, Stack, Typography } from "@mui/material";
import { Link as RLink } from "@remix-run/react";

import { Routes } from "~/constants/routes";

export function HomeExplanation() {
  return (
    <Stack
      spacing={5}
      sx={{
        textAlign: {
          xs: "center",
          sm: "left",
        },
        background: {
          xs: 'no-repeat url("./images/app_demo_banner_small.jpg") top / contain',
          sm: 'no-repeat url("./images/app_demo_banner_1.jpg") bottom / cover',
        },
        mt: 8,
        p: {
          xs: "70vw 5% 5% 5%",
          sm: "5%",
        },
      }}
    >
      <Typography
        component="span"
        sx={{
          width: {
            sm: "60%",
          },
          fontSize: {
            xs: "5vw",
            sm: "3vw",
          },
          fontWeight: "bold",
          mt: 4,
        }}
      >
        The safest and most flexible universal wishlist for public personalities.
      </Typography>
      <Typography
        component="p"
        sx={{
          width: {
            sm: "60%",
          },
          fontSize: {
            xs: "2.7vw",
            sm: "2vw",
          },
        }}
      >
        Say hello to WishTender, a universal wishlist and gift registry platform- specially designed
        for influencers and public personalities. Build your wishlist with gifts from any retailer
        and have full control of privacy and orders. We're customer-focused; Building out the site
        with feedback from customers like you every step of the way.
      </Typography>
      <Link component={RLink} to={Routes.auth.signUp()}>
        <Button
          disableElevation
          variant="contained"
          sx={{
            fontWeight: 600,
            background: (theme) => theme.gradient.main,
            ":hover": {
              background: (theme) => theme.gradient.mainReverse,
            },
          }}
        >
          Create Your Free Wishlist
        </Button>
      </Link>
    </Stack>
  );
}
